import React, { memo } from 'react';
// import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, Button } from '@mui/material';


const ConfirmationModal = ({ isOpen, handleClose, handleConfirm, item, setReasonCancellation, reasonCancellation, errorCancel }) => {

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Вы уверены?
        </Typography>
        <TextField onChange={(e) => setReasonCancellation(e.target.value)} name="Note" value={reasonCancellation}
          sx={{margin: '10px', width: "50vw"}}
        label="Причина отмены" className="outlined-size-normal" placeholder=""/>
        <Typography id="modal-modal-title">
        {errorCancel}
        </Typography>
        <Button variant="contained" onClick={handleConfirm}>Да</Button>
        <Button variant="contained" onClick={handleClose}>Нет</Button>
      </Box>
    </Modal>
  );
};

export default memo(ConfirmationModal);
// export default ConfirmationModal;
