import './App.css';
import { Routes, Route } from 'react-router-dom';
import Application from './components/Application/Application';
import Login from './components/Login/Login';
import FormUser from './components/FormUser/FormUser';
import AccountsLogin from './components/AccountsLogin/AccountsLogin';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Organization from "./components/Organizations/Organizations";
import ListApplication from "./components/ListApplication/ListApplication";
import OldUrlNotification from "./components/OldUrlNotification/OldUrlNotification";
function App() {

    return (
        <div className="App">
            <Routes>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/`} element={<Login/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/login`} element={<Login/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/application`} element={<Application/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/listApplication`} element={<ListApplication/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/createUser`} element={<FormUser/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/login/:code`} element={<AccountsLogin/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/changePassword`} element={<ChangePassword/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/organization`} element={<Organization/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/oldUrlNotification`} element={<OldUrlNotification/>}/>
                <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/*`} element={<Login/>}/>
            </Routes>

        </div>
    );
}

export default App;
