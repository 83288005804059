import React, { useEffect, useState } from 'react';
import { TextField, Button,  FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import axios from 'axios';
import './ApplicationForm.css';
import SuggestionsInput from './SuggestionsInput';
import PermissionStreet from './PermissionStreet';
import { addressValue } from '../../utils/addressValue';
import { formatter } from '../../utils/phoneNumber.js';
import { useNavigate } from 'react-router-dom';

const path = process.env.REACT_APP_PATH;
const url = process.env.REACT_APP_SERVER_URL;

const ApplicationForm = () => {
    
    let navigate = useNavigate();
    const [Reason, setReason] = useState('');
    const [Clarification, setClarification] = useState('');
    const [Address, setAddress] = useState('');
    const [Note, setNote] = useState('');
    const [Subdivision, setSubdivision] = useState(localStorage?.title);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [Permission, setPermission] = useState('');
    const [color, setColor] = useState('');
    const [ReasonOptions, setReasonOptions] = useState([]);
    const [AllCause, setAllCause] = useState([]);
    const [District, setDistrict] = useState('');
    const [phoneFields, setPhoneFields] = useState([{ 
        phone: localStorage?.phone ? localStorage.phone !== 'null' ? localStorage.phone : '' : '', 
        note: localStorage?.name ? localStorage.name : '',
    }]);
    const maxFields = 5;
    const [City, setCity] = useState('Москва');

    // const [Area, setArea] = useState('');
    // const [Street, setStreet] = useState('');
    // const [House, setHouse] = useState('');
  
    const handlePhoneChange = (value, index, field) => {
      const newPhoneFields = [...phoneFields];
      newPhoneFields[index][field] = value;
      setPhoneFields(newPhoneFields);
    };
  
    const addPhoneField = () => {
      if (phoneFields.length < maxFields) {
        setPhoneFields([...phoneFields, { phone: '', note: '' }]);
      }
    };

    useEffect(() => {
        axios.get(`${url}/cause/${localStorage?.type}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => { 
            if ( response.status === 201) {
                setReasonOptions(response.data.uniqueObjects)
                setAllCause( response.data.cause)
            } else if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                setMessage('Произошла ошибка')
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch((error) => {
            setMessage(error.err)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })
    }, [])

    const handleApplication = ( event ) => {
        event.preventDefault();

        if(!Reason || !Clarification){
            setColor('red')
            setMessage("Для оформления заявки укажите причину и уточнение аварии")
        } else {
            for(let i = 0; i < phoneFields.length; i++) {
                if(phoneFields[i].phone) {
                    const checkPhone = formatter(phoneFields[i].phone, 'ru')
                    if(!checkPhone) {
                        setColor('red')
                        setMessage(`Неверный формат или номер телефона - ${phoneFields[i].phone}`)
                        setTimeout(()=> {
                            setMessage('')
                        }, 5000)
                        return
                    } else {
                        phoneFields[i].phone = checkPhone
                    }
                } else {
                    setColor('red')
                    setMessage(`Укажите телефон для связи`)
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000)
                    return
                }
            }    
            
            if(!Address){
                setColor('red')
                setMessage("Введите адрес в поле Введите адрес")
                setTimeout(() => {
                    setMessage('')
                }, 7000)
            // } else if (City === 'Москва' && !District) {
            //     setColor('red')
            //     setMessage("Выберите округ")
            //     setTimeout(() => {
            //         setMessage('')
            //     }, 7000)
            } else {
                setLoading(true)
                axios.post(`${url}/application`, { 
                    Reason, 
                    Clarification, 
                    Address: Address, 
                    Note, 
                    Permission: Permission, 
                    phoneFields: phoneFields.filter(el => el.phone), 
                    Subdivision,
                    District,
                    City
                }, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                .then((response) => { 
                    setLoading(false)
                    if ( response.status === 201) {
                        setAddress('');
                        setReason('');
                        setNote('');
                        setClarification('');
                        setPermission('');
                        setSubdivision(localStorage?.title);
                        setPhoneFields([{
                            phone: localStorage?.phone ? localStorage.phone !== 'null' ? localStorage.phone : '' : '',  
                            note: localStorage?.name ? localStorage.name : '',
                        }]);
                        setColor('green')
                        setMessage(response.data.message)
                        setDistrict('')
                        setTimeout(()=> {
                            setMessage('')
                        }, 5000)
                    }  else if (response.status === 210) {
                        navigate(`${path}/changePassword`, { replace: true });
                    } else {
                        setColor('red')
                        setMessage(response.data.message)
                        setTimeout(()=> {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setColor('red')
                    setMessage(error?.response?.data?.err)
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000)
                    // console.log('ошибка', error.response.data.err)
                })
            }
        }

    }
    
    const updateCity = (e) => {
        setCity(e.target.value)
        setAddress('')
    }

    const deletePhoneField = (indexToDelete) => {
        const updatedFields = [...phoneFields];
        updatedFields.splice(indexToDelete, 1);
        setPhoneFields(updatedFields);
    };

    const cleanForm = () => {
        setAddress('');
        setReason('');
        setNote('');
        setClarification('');
        setPermission('');
        setSubdivision(localStorage?.title);
        setPhoneFields([{
            phone: localStorage?.phone ? localStorage.phone !== 'null' ? localStorage.phone : '' : '',  
            note: localStorage?.name ? localStorage.name : '',
        }]);
        setDistrict('')
    }
    return (
        <div className='form' style={{marginTop: '5px'}}>
            <h3>Новая заявка</h3>
            {message && (
                <h3 style={{color: color, marginTop: '1px'}}>{message}</h3>
            )}
            <form onSubmit={handleApplication} id='formApplication'>
                <div className='formBlockReason'>
                    <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }}>
                        <InputLabel>Причина обращения</InputLabel>
                        <Select
                        label="Причина обращения"
                        value={Reason}
                        onChange={(e) => setReason(e.target.value)} 
                        >
                            {ReasonOptions.map((option) => (
                                <MenuItem key={option.causeCode} value={option.causeCode}>
                                    {option.cause}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }}>
                    <InputLabel>Уточнение</InputLabel> 
                    <Select
                        label="Уточнение"
                        // placeholder='Сначала выберите причину'
                        value={Clarification}
                        onChange={(e) => setClarification(e.target.value)} 
                    >
                    {Reason && (
                        AllCause.filter(el => el.causeCode === Reason).map((option, ind) =>( 
                            <MenuItem key={ind} value={option.clarificationCode}>
                            {option.clarification}
                            </MenuItem>
                        ))
                    )}
                    </Select>
                    </FormControl>
                </div >

                <div className='AddressDistrict'>
                    <FormControl variant="outlined" style={{ margin: '10px', width: '30%'}}>
                        {/* <InputLabel>Выберите город</InputLabel> */} 
                        <InputLabel>Город</InputLabel>
                        <Select
                        // label="Выберите город"
                        label="Город"
                        value={City}
                        onChange={(e) => updateCity(e)} 
                        >
                            <MenuItem value="Москва">Москва</MenuItem>
                            {/* <MenuItem value="Московская область">Московская область</MenuItem> */}
                        </Select>
                    </FormControl>

                    <SuggestionsInput Address={Address} setAddress={setAddress} 
                        setDistrict={setDistrict} City={City}/>

                        {City === 'Москва' && (
                            <FormControl variant="outlined" style={{ margin: '10px', width: '30%'}}>
                                <InputLabel>Округ</InputLabel>
                                <Select
                                label="Округ"
                                value={District}
                                onChange={(e) => setDistrict(e.target.value)} 
                                disabled
                                >
                                    {addressValue.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                </div>

                <div>
                    <PermissionStreet Permission={Permission} City={City} Address={Address} setPermission={setPermission} />
                </div>
                                
                <div className='formBlock'>
                    <TextField onChange={(e) => setNote(e.target.value)} name="Note" value={Note}
                        sx={{margin: '10px', width: "50vw"}}
                        label="Дополнительная информация" className="outlined-size-normal" placeholder=""/>

                    <TextField onChange={(e) => setSubdivision(e.target.value)} name="Subdivision" value={Subdivision}
                        sx={{margin: '10px', width: '30vw' }}
                        label="Подразделение" className="outlined-size-normal" placeholder=""/>

                </div>

                <div>
                    {phoneFields.map((phoneField, index) => (
                        <div key={index} className='PhoneBlock'>
                            <TextField
                                onChange={(e) => handlePhoneChange(e.target.value, index, 'phone')}
                                name={`Phone${index + 1}`}
                                value={phoneField.phone}
                                sx={{ margin: '10px', width: '30vw' }}
                                label="Телефон"
                                className="outlined-size-normal"
                                placeholder=""
                            />

                            <TextField
                                onChange={(e) => handlePhoneChange(e.target.value, index, 'note')}
                                name={`PhoneNote${index + 1}`}
                                value={phoneField.note}
                                sx={{ margin: '10px', width: '30vw' }}
                                label="Примечание к телефону"
                                className="outlined-size-normal"
                                placeholder=""
                            />

                            {index !== 0 ? 
                            <Button onClick={() => deletePhoneField(index)}>
                                Удалить номер
                            </Button>
                            :
                            <></>
                            }
                        </div>
                    ))}

                    {phoneFields.length < maxFields && (
                        <Button onClick={addPhoneField}>
                            Добавить телефон
                        </Button>
                    )}
                </div>

                {loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress/>
                    </div>
                ):(
                    <div>
                        <Button type="submit" sx={{margin: '20px'}} variant="contained" color="primary">
                            Отправить заявку
                        </Button>
                        <Button  sx={{margin: '20px'}} variant="contained" color="error" onClick={cleanForm}>
                            Очистить форму
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
};

// export default React.memo(shopList);
export default ApplicationForm;