import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const AccountsLogin = ( ) => {
    const { code } = useParams();
    const [Error, setError] = useState('');
    let navigate = useNavigate();
    
    useEffect(()=> {
        axios.get(`${url}/login/${code}`)
        .then((response) => {
            if (response.status === 200) {
                localStorage.organizationId = response.data.user?.organizationId
                localStorage.phone = response.data.user?.phone
                localStorage.title = response.data.user.organization.title
                localStorage.name = response.data.user.name
                localStorage.access = response.data.token.access
                localStorage.expire = response.data.token.expire
                localStorage.userName = response.data.user.name
                localStorage.id = response.data.user.id
                localStorage.status = response.data.user.status
                localStorage.organization = response.data.user?.organization?.title
                localStorage.type = response.data.user?.organization?.type
                navigate(`${path}/application`, {replace: true});
            } else {
                setError(response.data.err)
                setTimeout(() => {
                    setError('')
                }, 5000)
            }
        })
        .catch((error) => {
            navigate(`${path}/login`, {replace: true});
        })
    }, [])

    return (
        <div style={{margin: '1vw', marginTop: "20px"}}>
            <h3>Пожалуйста подождите</h3>
            {Error && <h4 style={{'color': 'red'}}>{Error}</h4>}
        </div>
    );
};

export default AccountsLogin;