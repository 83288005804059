import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import ButtonError from '../ButtonError/ButtonError';
import ListOrganization from "../ListOrganization/ListOrganization";
import OrganizationClarificationModal from "../ListOrganization/OrganizationClarificationModal";
import ModalMessage from "../ModalMessage/ModalMessage";
import InstructionUploadButton from "../InstructionUploadButton/InstructionUploadButton";

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const Organization = () => {
    const time = 2000
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [Title, setTitle] = useState('');
    const [OrganizationList, setOrganizationList] = useState([]);
    const [TypeOptions, setTypeOptions] = useState([]);
    const [SelectedType, setSelectedType] = useState('');
    const [CustomType, setCustomType] = useState('');
    const [isCustomType, setIsCustomType] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [editedData, setEditedData] = useState({
        title: '',
        type: '',
        customType: '',
    });
    const [Causes, setCauses] = useState([]);
    const [Clarifications, setClarifications] = useState([]);
    const [SelectedCause, setSelectedCause] = useState(1);

    let navigate = useNavigate();

    useEffect(() => {
        if (localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if (localStorage?.expire > nowDate) {
                updateListOrganizationType();
                updateListOrganization();
            } else {
                navigate(`${path}/login`, { replace: true });
            }
        } else {
            navigate(`${path}/login`, { replace: true });
        }
    }, []);

    const updateListOrganizationType = () => {
        axios.get(`${url}/organization/type`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setTypeOptions(response.data);
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setMessage('Произошла ошибка при выгрузке типов организаций, попробуйте позже');
                    setTimeout(() => {
                        setMessage('');
                    }, time);
                }
            })
            .catch(() => {
                setMessage('Произошла ошибка при выгрузке типов организаций, попробуйте позже');
                setTimeout(() => {
                    setMessage('');
                }, time);
            });
    };

    const updateListOrganization = () => {
        const endpoint = Number(localStorage?.status) === 3 ? `${url}/organization/` : `${url}/organization/available`;

        axios.get(endpoint, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if (response.status === 201) {
                    setOrganizationList(response.data.organizations);
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setMessage('Произошла ошибка при выгрузке организаций, попробуйте позже');
                    setTimeout(() => {
                        setMessage('');
                    }, time);
                }
            })
            .catch(() => {
                setMessage('Произошла ошибка при выгрузке организаций, попробуйте позже');
                setTimeout(() => {
                    setMessage('');
                }, time);
            });
    };

    const applicationNavigate = () => {
        navigate(`${path}/application`, { replace: true });
    };

    const handleCreateButtonClick = async () => {
        setEditItem(true);
        setEditedData({
            title: '',
            type: '',
            customType: '',
        });
        try {
            const response = await axios.get(`${url}/organization/newOrg`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            });
            setCauses(response.data.cause);
            setClarifications(response.data.clarifications);
        } catch (error) {
            console.error(error);
            setMessage('Произошла ошибка при загрузке данных, попробуйте позже');
            setTimeout(() => {
                setMessage('');
            }, time);
        }
    };

    const handleCheckboxClarificationsChange = (id) => {
        setClarifications(prevClars =>
            prevClars.map(clar => {
                if (clar.causeId === SelectedCause) {
                    clar.clarification = clar.clarification.map(el => {
                        if (el.id === id) {
                            el.selected = !el.selected;
                        }
                        return el;
                    });
                }
                return clar;
            })
        );
    };

    const saveOrg = async () => {
        try {
            if ( !editedData.title || !editedData.type || (editedData.type === 'Другой' && !editedData.customType)) {
                setColor('red')
                setMessage('Заполните все данные')
                setTimeout(()=> {
                    setMessage('')
                }, time)
            } else {
                const response = await axios.post(`${url}/organization/create`, {
                    ...editedData,
                    Clarifications: Clarifications, // добавление кларификаций
                }, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                });
                if (response.status === 201) {
                    setColor('green');
                    setMessage(response.data.message);
                    updateListOrganization();
                    updateListOrganizationType();
                    setEditItem(null);
                    setTimeout(() => {
                        setMessage('');
                    }, time);
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, {replace: true});
                } else {
                    setColor('orange');
                    setMessage(response.data.message);
                    setTimeout(() => {
                        setMessage('');
                    }, time);
                }
            }
        } catch (error) {
            setColor('red');
            setMessage(error.response.data.err);
            setTimeout(() => {
                setMessage('');
            }, time);
        }
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
                <Header applicationNavigate={applicationNavigate} />
                <ButtonError />
                {Number(localStorage?.status) === 3 ?
                    <InstructionUploadButton/>
                    :
                    <></>
                }
            </div>

            <div style={{ margin: '1vw', marginTop: "20px" }}>
                <Button onClick={handleCreateButtonClick} sx={{ margin: '20px' }} variant="contained" color="primary">
                    Создать организацию
                </Button>

                <OrganizationClarificationModal
                    editItem={editItem}
                    setEditItem={setEditItem}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    TypeOptions={TypeOptions}
                    Causes={Causes}
                    SelectedCause={SelectedCause}
                    setSelectedCause={setSelectedCause}
                    Clarifications={Clarifications}
                    handleCheckboxClarificationsChange={handleCheckboxClarificationsChange}
                    saveEdit={saveOrg}
                    headerTitle="Создание новой организации"
                />

                <ModalMessage message={Message} color={Color}/>
                <ListOrganization OrganizationList={OrganizationList} updateListOrganization={updateListOrganization} />
            </div>
        </>
    );
};

export default Organization;
