export const addressValue = [
    { label: 'Центральный', area: [
        { value: 'Арбат' }, 
        { value: 'Басманный' }, 
        { value: 'Замоскворечье' }, 
        { value: 'Красносельский' }, 
        { value: 'Мещанский' }, 
        { value: 'Премненский' }, 
        { value: 'Таганский' }, 
        { value: 'Тверской' }, 
        { value: 'Хамовники' }, 
        { value: 'Якиманка' }, 
    ] },
    { label: 'Северный', area: [
        { value: 'Аэропорт' }, 
        { value: 'Беговой' }, 
        { value: 'Бескудниковский' }, 
        { value: 'Войковский' }, 
        { value: 'Восточное Дегунино' }, 
        { value: 'Головинский' }, 
        { value: 'Дмитровский' }, 
        { value: 'Западное Дегунино' }, 
        { value: 'Коптево' }, 
        { value: 'Левобережный' }, 
        { value: 'Молжаниновский' }, 
        { value: 'Савеловский' }, 
        { value: 'Сокол' }, 
        { value: 'Тимирязевский' }, 
        { value: 'Ховрино' }, 
        { value: 'Хорошевский' }, 
    ] },
    { label: 'Северо-восточный', area: [
        { value: 'Алексеевский' }, 
        { value: 'Алтуфьевский' }, 
        { value: 'Бабушкинский' }, 
        { value: 'Бибирево' }, 
        { value: 'Бутырский' }, 
        { value: 'Лианозово' }, 
        { value: 'Лосиноостровский' }, 
        { value: 'Марфино' }, 
        { value: 'Марьина Роща' }, 
        { value: 'Останкинский' }, 
        { value: 'Отрадное' }, 
        { value: 'Ростокино' }, 
        { value: 'Свиблово' }, 
        { value: 'Северное Медведково' }, 
        { value: 'Северный' }, 
        { value: 'Южное Медведково' }, 
        { value: 'Ярославский' }, 
    ] },
    { label: 'Восточный', area: [
        { value: 'Богородское' }, 
        { value: 'Вешняки' }, 
        { value: 'Восточнок Измайлово' }, 
        { value: 'Восточный' }, 
        { value: 'Гольяново' }, 
        { value: 'Ивановское' }, 
        { value: 'Измайлово' }, 
        { value: 'Косино-Ухтомский' }, 
        { value: 'Метрогородок' }, 
        { value: 'Новогиреево' }, 
        { value: 'Новокосино' }, 
        { value: 'Перово' }, 
        { value: 'Преображенское' }, 
        { value: 'Северное Измайлово' }, 
        { value: 'Соколиная Гора' }, 
        { value: 'Сокольники' }, 
    ] },
    { label: 'Юго-восточный', area: [
        { value: 'Выхино-Жулебино' }, 
        { value: 'Капотня' }, 
        { value: 'Кузьминки' }, 
        { value: 'Лефортово' }, 
        { value: 'Люблино' }, 
        { value: 'Марьино' }, 
        { value: 'Некрасовка' }, 
        { value: 'Нижегородский' }, 
        { value: 'Печатники' }, 
        { value: 'Рязанский' }, 
        { value: 'Текстильщики' }, 
        { value: 'Южнопортовый' }, 
    ] },
    { label: 'Южный', area: [
        { value: 'Бирюлево Восточное' }, 
        { value: 'Бирюлево Западное' }, 
        { value: 'Братеево' }, 
        { value: 'Даниловский' }, 
        { value: 'Донской' }, 
        { value: 'Зябликово' }, 
        { value: 'Москворечье-Сабурово' }, 
        { value: 'Нагатино-Садовники' }, 
        { value: 'Нагатинский Затон' }, 
        { value: 'Нагорный' }, 
        { value: 'Орехово-Борисово Северное' }, 
        { value: 'Орехово-Борисово Южное' }, 
        { value: 'Царицыно' }, 
        { value: 'Чертаново Северное' }, 
        { value: 'Чертаново Центральное' }, 
        { value: 'Чертаново Южное' }, 
    ] },
    { label: 'Юго-западный', area: [
        { value: 'Академический' }, 
        { value: 'Гагаринский' }, 
        { value: 'Зюзино' }, 
        { value: 'Коньково' }, 
        { value: 'Котловка' }, 
        { value: 'Ломоносовский' }, 
        { value: 'Обручевский' }, 
        { value: 'Северное Бутово' }, 
        { value: 'Теплый Стан' }, 
        { value: 'Черемушки' }, 
        { value: 'Южное Бутово' }, 
        { value: 'Ясенево' }, 
    ] },
    { label: 'Западный', area: [
        { value: 'Внуково' }, 
        { value: 'Дорогомилово' }, 
        { value: 'Крылатское' }, 
        { value: 'Кунцево' }, 
        { value: 'Можайский' }, 
        { value: 'Ново-Переделкино' }, 
        { value: 'Очаково-Матвеевское' }, 
        { value: 'Проспект Вернадского' }, 
        { value: 'Раменки' }, 
        { value: 'Солнцево' }, 
        { value: 'Тропарево-Никулино' }, 
        { value: 'Филевский Парк' }, 
        { value: 'Фили-Давыдково' }, 
    ] },
    { label: 'Северо-западный', area: [
        { value: 'Куркино' }, 
        { value: 'Митино' }, 
        { value: 'Покровское-Стрешнево' }, 
        { value: 'Северное Тушино' }, 
        { value: 'Строгино' }, 
        { value: 'Хорошево-Мневники' }, 
        { value: 'Щукино' }, 
        { value: 'Южное Тушино' }, 
    ] },
    { label: 'Зеленоградский', area: [
        { value: 'Крюково' }, 
        { value: 'Матушкино' }, 
        { value: 'Савёлки' }, 
        { value: 'Силино' }, 
        { value: 'Старое Крюково' }, 
    ] },
    { label: 'Московская область', area: [
        { value: 'Красногорск' }, 
        { value: 'Химки' }, 
        { value: 'Солнечногорск' }, 
        { value: 'Долгопрудный' },
        { value: 'Мытищи' }, 
        { value: 'Балашиха' }, 
        { value: 'Реутов' },
        { value: 'Люберцы' }, 
        { value: 'Дзержинский' }, 
        { value: 'Котельники' },
        { value: 'Ленинский' }, 
        { value: 'Подольск' }, 
        { value: 'Одинцовский' }, 
        { value: 'Королев' }, 
    ] },
    { label: 'Новомосковский', area: [
        { value: 'Внуковское' }, 
        { value: 'Воскресенское' }, 
        { value: 'Десёновское' }, 
        { value: 'Кокошкино' }, 
        { value: 'Марушкинское' }, 
        { value: 'Московский' }, 
        { value: 'Мосрентген' }, 
        { value: 'Рязановское' }, 
        { value: 'Сосенское' }, 
        { value: 'Филимоновское' }, 
        { value: 'Щербинка' }, 
    ] },
    { label: 'Троицкий', area: [
        { value: 'Вороновское' }, 
        { value: 'Киевский' }, 
        { value: 'Кленовское' }, 
        { value: 'Краснопахарское' }, 
        { value: 'Михайлово-Ярцевское' }, 
        { value: 'Новофедоровское' }, 
        { value: 'Первомайское' }, 
        { value: 'Роговское' }, 
        { value: 'Троицк' }, 
        { value: 'Щаповское' }, 
    ] },
];