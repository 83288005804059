import React, { useState }  from 'react';
import { memo } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const ModalMessage = ({ message, color, time }) => {
    return (
        <>
            <Dialog open={Boolean(message)}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <h3 style={{color}}>{message}</h3>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default memo(ModalMessage);