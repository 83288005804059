export function defineStatus(status) {
    switch(status) {
        case 'inwork': 
            return 'В работе'
        case 'closed': 
            return 'Работы завершены'
        case 'canceled': 
            return 'Авария не в сфере деятельности АО "Мосводоканал"'
        case 'canceledOk': 
            return 'Нарушений на сетях водоснабжения и водоотведения не обнаружено'
        case 'create': 
            return 'В обработке'
        default:
            return 'Ожидаем информацию'
      }
}