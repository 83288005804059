import {Button, Modal, Box, TextField, InputLabel, CircularProgress, Typography} from '@mui/material';
import React, { useState } from 'react';
import './InstructionUploadButton.css';
import axios from 'axios';
import { formatter } from '../../utils/phoneNumber';
import ModalMessage from "../ModalMessage/ModalMessage";

const url = process.env.REACT_APP_SERVER_URL;

function InstructionUploadButton() {
    const time = 2000;
    const [isOpenApp, setIsOpenApp] = useState(false);
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [instructionName, setInstructionName] = useState('');
    const [instruction, setInstruction] = useState(null);

    const modalClosed = () => {
        setIsOpenApp(false);
        setInstruction(null);
        setInstructionName('')
    };

    const handleInstructionChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setInstructionName(file.name);
            setInstruction(file);
        } else {
            setMessage('Выберите файл в формате PDF');
            setColor('red');
            setTimeout(() => {
                setMessage('');
            }, time);
        }
    };

    const uploadInstruction = () => {
        if (!instruction) {
            setMessage('Выберите файл для загрузки');
            setColor('red');
            setTimeout(() => {
                setMessage('');
            }, time);
            return;
        }

        const formData = new FormData();
        formData.append('instruction', instruction);

        axios.post(`${url}/instruction/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setMessage('Инструкция успешно загружена');
                    setColor('green');
                    setInstruction(null);
                    setInstructionName('')
                    modalClosed();
                } else {
                    setMessage('Ошибка загрузки инструкции');
                    setColor('red');
                }
                setTimeout(() => {
                    setMessage('');
                }, time);
            })
            .catch(error => {
                setMessage('Произошла ошибка при загрузке инструкции');
                setColor('red');
                setTimeout(() => {
                    setMessage('');
                }, time);
            });
    }

    return (
            <div className='buttonDiv'>
                <Button
                    onClick={() => setIsOpenApp(true)}
                    sx={{
                        top: '5px',
                        right: '20px',
                        color: 'red',
                        backgroundColor: '#add5f5',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        padding: '12px',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    📝
                </Button>

                <Modal
                    open={isOpenApp}
                    onClose={modalClosed}
                    size='medium'
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                                <div>
                                    <Typography style={{margin: '20px'}}>Загрузить инструкцию:</Typography>
                                </div>
                                <div>
                                    <Button onClick={modalClosed} sx={{margin: '20px'}}>❌</Button>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-evenly'}}>
                                <div>
                                    <input
                                        id="upload"
                                        type="file"
                                        onChange={handleInstructionChange}
                                        style={{display: 'none'}}
                                    />
                                    <label htmlFor="upload">
                                        <Button variant="contained" component="span" style={{backgroundColor: instructionName ? '#00b285' : '#1976d2'}}>
                                            {instructionName ? instructionName: "Выбрать файл"}
                                        </Button>
                                    </label>

                                </div>
                                <div>
                                    <Button onClick={uploadInstruction} variant="contained" component="span" style={{backgroundColor: instructionName ? '#00b285' : '#1976d2'}}>Загрузить</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <ModalMessage message={Message} color={Color}/>
            </div>
    )
        ;
}

export default InstructionUploadButton;