import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Pagination, ListItem, ListItemText, List,
} from '@mui/material';
import { memo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { checkPassword } from '../../utils/checkPassword';
import {status, statusArray} from '../../utils/status';
import ModalMessage from "../ModalMessage/ModalMessage";
import {formatter} from "../../utils/phoneNumber";
import UserOrganizationModal from "./UserOrganizationModal";

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH_ACCOUNT;

const ListUser = ({ UsersList, updateListUser, setUsersList }) => {
    const itemsPerPage = 10;

    let navigate = useNavigate();
    const [DeleteItem, setDeleteItem] = useState(null);
    const [PasswordItem, setPasswordItem] = useState(null);
    const time = 2000
    const [editItem, setEditItem] = useState(null);
    const [editOrg, setEditOrg] = useState(null);
    const [UrlItem, setUrlItem] = useState(null);
    const [NewPassword, setNewPassword] = useState('');
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [editedData, setEditedData] = useState({});
    const [arrayOrg, setArrOrg] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [filteredOrgs, setFilteredOrgs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [organizationId, setOrganizationId] = useState(null);
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                axios.get(`${url}/accounts/searchOrganizations/${searchTerm}`, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then(response => {
                        setUsersList(response.data)
                    })
                    .catch(error => {
                        console.error('Ошибка фильтрации организаций:', error);
                    });
            } else {
                updateListUser()
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const sortFunction = (array) => {
        const allTypes = Array.from(new Set(array.map(org => org.type))).sort();
        setSelectedTypes(allTypes);
    }

    const handleFilterChange = (type) => {
        setSelectedTypes(prevSelectedTypes => {
            return prevSelectedTypes.includes(type)
                ? prevSelectedTypes.filter(t => t !== type)
                : [...prevSelectedTypes, type];
        });
    };


    const handleCheckboxChange = (id) => {
        setFilteredOrgs(prevOrgs => {
            return prevOrgs.map(org => {
                if (org.id === id) {
                    return { ...org, available: !org.available };
                }
                return org;
            });
        });
    };

    const handleSaveOrg = async () => {
        const updatesOrgs = filteredOrgs
            .filter(org => org.available === true)
            .map(org => org.id)
        axios.post(`${url}/accounts/editOrgs`, {updatesOrgs, editOrg}, {
            headers: {
                'jwt-token': localStorage?.access,
            }            })
            .then((response) => {
                if (response.status === 201) {
                    setColor('green')
                    setMessage('Успешное изменение списка организаций')
                    setEditOrg(null)
                    setTimeout(() => {
                        setMessage('')
                    }, time)
                }
            })
            .catch((error) => {
                setMessage('Произошла ошибка при обновлении списка организаций, попробуйте позже')
                setTimeout(() => {
                    setMessage('')
                }, time)
            })
    };

    const deleteUser = (user) => {
        setDeleteItem(null);
        axios.delete(`${url}/accounts/${user.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setColor('green')
                    setMessage(response.data.message)
                    updateListUser()
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setColor('orange')
                    setMessage(response.data.message)
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(error.response.data.err)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            });
    }

    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const currentUsers = UsersList.slice(indexOfFirstUser, indexOfLastUser);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const saveEdit = () => {
        const checkPhone = formatter(editedData.phone, 'ru')
        if(!checkPhone) {
            setColor('red')
            setMessage(`Неверный формат или номер телефона - ${editedData.phone}`)
            setTimeout(() => {
                setMessage('')
            }, time)
            return
        }
        axios.post(`${url}/accounts/editUser`, {...editedData, userId: editItem.id}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setColor('green')
                    setMessage(response.data.message)
                    updateListUser()
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setColor('orange')
                    setMessage(response.data.message)
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(error.response.data.err)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            })

        setEditItem(null);
    };

    const handleEdit = (item) => {
        setEditItem(item)
        setEditedData(item)
    }

    useEffect(()=> {
        const orgArray = arrayOrg
            .filter(org => {
                // Фильтруем по выбранным типам
                return selectedTypes.length === 0 ? false : selectedTypes.includes(org.type);
            })
            .sort((a, b) => a.title.localeCompare(b.title))
            .sort((a, b) => (b.available === true) - (a.available === true));
        setFilteredOrgs(orgArray)
    }, [selectedTypes])


    const orgEdit = (item) => {
        setEditOrg(item)
        if(Number(localStorage?.status) === 3) {
            axios.get(`${url}/organization/user/${item.id}`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setArrOrg(response.data.allOrganizations)
                        sortFunction(response.data.allOrganizations)
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, {replace: true});
                    } else {
                        setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, time)
                    }
                })
                .catch((error) => {
                    setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, time)
                })
            // setEditedOrg({title: item.title})
        }
        else {
            axios.get(`${url}/organization/available`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setArrOrg(response.data.organizations)
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, {replace: true});
                    } else {
                        setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, time)
                    }
                })
                .catch((error) => {
                    setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, time)
                })
            // setEditedOrg({title: item.title})
        }
        // setEditOrg(item)
    }

    const editUrl = (item) => {
        axios.post(`${url}/accounts/editUrl`, {id: item.id}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setColor('green')
                    setMessage(response.data.message)
                    updateListUser()
                    setEditedData(response.data.updatedUrlUser)
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setColor('orange')
                    setMessage(response.data.message)
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(error.response.data.err)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            })
        setUrlItem(null)
    }

    const editPassword = () => {
        const checkPass = checkPassword(NewPassword);
        if(!checkPass) {
            setColor('red')
            setMessage('Пароль не подходит под условия')
            setTimeout(() => {
                setMessage('')
            }, time)
            return;
        }
        axios.post(`${url}/accounts/newPassword`, {id: PasswordItem.id, NewPassword}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setColor('green')
                    setMessage(response.data.message)
                    updateListUser()
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setColor('orange')
                    setMessage(response.data.message)
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(error.response.data.err)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            })
        setPasswordItem(null)
    }

    const cancelNewPassword  = () => {
        setNewPassword('')
        setPasswordItem(null)
    }

    return (
        <div style={{margin: '1vw', marginTop: "20px"}}>
            {Number(localStorage?.status) === 3 ?
                <div style={{display: 'flex'}}>
                    <TextField
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        sx={{marginBlockEnd: '20px', width: "30vw", textAlign: 'left'}}
                        label="Фильтр по названию организации"
                        className="outlined-size-normal"
                        placeholder=""
                    />
                    <List>
                        {organizations.map(org => (
                            <ListItem key={org.id} onClick={() => setOrganizationId(org.id)}>
                                <ListItemText primary={org.name}/>
                            </ListItem>
                        ))}
                    </List>
                    <List>
                        {users.map(user => (
                            <ListItem key={user.id}>
                                <ListItemText primary={user.name}/>
                            </ListItem>
                        ))}
                    </List>
                </div>
                :
                <div></div>
            }

            {UsersList?.length ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Логин</TableCell>
                                    <TableCell>Имя</TableCell>
                                    <TableCell>Статус</TableCell>
                                    <TableCell>Номер телефона</TableCell>
                                    {Number(localStorage?.status) === 3 ?
                                        <TableCell>Список организаций</TableCell>
                                        : <></>}
                                    {Number(localStorage?.status) >= 1 ?
                                        <TableCell>Изменить</TableCell>
                                        : <></>}
                                    {Number(localStorage?.status) >= 1 ?
                                        <TableCell>Удалить</TableCell>
                                        : <></>}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentUsers.map((item) => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.login}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{status[item.status]}</TableCell>
                                                <TableCell>{item.phone}</TableCell>
                                                {Number(localStorage?.status) === 3 ? (
                                                    <TableCell>
                                                        {Number(item?.status) === 3 ? (
                                                            <Button variant="contained"
                                                                    style={{backgroundColor: '#00b285'}}>Все
                                                                организации</Button>
                                                        ) : (
                                                            Number(item?.status) >= 2 ? (
                                                                <Button variant="contained" color="primary"
                                                                        onClick={() => orgEdit(item)}>Организации</Button>
                                                            ) : (
                                                                <Button variant="contained" color="primary"
                                                                        disabled>Организации</Button>
                                                            )
                                                        )}
                                                    </TableCell>
                                                ) : (
                                                    <></>
                                                )}
                                                {(Number(localStorage?.status) >= 1 && Number(localStorage?.status) > Number(item?.status)) || Number(localStorage?.status) === 3 || Number(localStorage?.id) === Number(item?.id) ?
                                                    <TableCell>
                                                        <Button variant="contained" color="primary"
                                                                onClick={() => handleEdit(item)}>Изменить</Button>
                                                    </TableCell>
                                                    :
                                                    <TableCell>
                                                        <Button variant="contained" color="primary"
                                                                disabled>Изменить</Button>
                                                    </TableCell>
                                                }
                                                {(Number(localStorage?.status) >= 1 && Number(localStorage?.status) > Number(item?.status)) || Number(localStorage?.status) === 3 ?
                                                    <TableCell>
                                                        <Button variant="contained" color="error"
                                                                onClick={() => setDeleteItem(item)}>Удалить</Button>
                                                    </TableCell>
                                                    :
                                                    <TableCell>
                                                        <Button variant="contained" color="error" disabled>Удалить</Button>
                                                    </TableCell>}
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={Boolean(DeleteItem)} onClose={() => setDeleteItem(null)}>
                        <DialogTitle>Вы точно хотите удалить {DeleteItem?.name}?</DialogTitle>
                        <DialogContent>
                            <DialogActions>
                                <Button onClick={() => setDeleteItem(null)}>Нет</Button>
                                <Button onClick={() => deleteUser(DeleteItem)} color="error">
                                    Да
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <UserOrganizationModal editOrg={editOrg} setEditOrg={setEditOrg} arrayOrg={arrayOrg}
                                           selectedTypes={selectedTypes} handleFilterChange={handleFilterChange}
                                           handleCheckboxChange={handleCheckboxChange} handleSaveOrg={handleSaveOrg}
                                           filteredOrgs={filteredOrgs} setArrOrg={setArrOrg}
                                           setSelectedTypes={setSelectedTypes}/>

                    <Dialog open={Boolean(editItem)} onClose={() => setEditItem(null)}>
                        <DialogTitle>Редактировать данные {editItem?.name}</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Логин"
                                type='text'
                                value={editedData.login}
                                onChange={(e) => setEditedData({...editedData, login: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Имя"
                                type='text'
                                value={editedData.name}
                                onChange={(e) => setEditedData({...editedData, name: e.target.value})}
                            />
                            {Number(localStorage?.status) === 3 ?
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Статус</InputLabel>
                                    <Select
                                        value={editedData.status}
                                        onChange={(e) => setEditedData({...editedData, status: e.target.value})}
                                        label="Статус"
                                    >
                                        {statusArray.map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> : <></>}
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Номер телефона"
                                type='text'
                                value={editedData.phone}
                                onChange={(e) => setEditedData({...editedData, phone: e.target.value})}
                            />
                            {editedData.url ? (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Ссылка"
                                    type='text'
                                    value={`${path}/${editedData.url}`}
                                    disabled
                                />
                            ) : (<></>)}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setUrlItem(editedData)}>
                                {editedData.url ? ('Изменить ссылку') : ('Сгенерировать ссылку')} </Button>
                            <Button onClick={() => setPasswordItem(editedData)}>Новый пароль</Button>
                            <Button onClick={() => setEditItem(null)}>Отмена</Button>
                            <Button onClick={saveEdit} color="primary">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={Boolean(UrlItem)} onClose={() => setUrlItem(null)}>
                        <DialogTitle>Вы точно хотите изменить ссылку для {UrlItem?.name}?</DialogTitle>
                        <DialogContent>
                            <DialogActions>
                                <Button onClick={() => setUrlItem(null)}>Нет</Button>
                                <Button onClick={() => editUrl(UrlItem)} color="error">
                                    Да
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={Boolean(PasswordItem)} onClose={() => setPasswordItem(null)}>
                        <DialogTitle>Задать новый пароль для {PasswordItem?.name}</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Пароль"
                                type='text'
                                value={NewPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => cancelNewPassword()}>Отмена</Button>
                            <Button onClick={editPassword} color="primary">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <ModalMessage message={Message} color={Color}/>
                    <Pagination
                        count={Math.ceil(UsersList.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}
                    />
                </>
            ) : (
                <h3>Список пользователей пуст, создайте их в форме выше</h3>
            )}
        </div>
    );
};

export default memo(ListUser);