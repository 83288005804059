import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Pagination
} from '@mui/material';
import { memo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ModalMessage from "../ModalMessage/ModalMessage";
import OrganizationClarificationModal from "./OrganizationClarificationModal";

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH_ACCOUNT;

const ListOrganization = ({ OrganizationList, updateListOrganization }) => {
    const itemsPerPage = 10;
    let navigate = useNavigate();
    const time = 2000
    const [DeleteItem, setDeleteItem] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [editedData, setEditedData] = useState({
        title: '',
        type: '',
        customType: '',
    });
    const [TypeOptions, setTypeOptions] = useState([]);
    const [Causes, setCauses] = useState([]);
    const [Clarifications, setClarifications] = useState([]);
    const [SelectedCause, setSelectedCause] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const headerTitle = `Редактировать данные ${editItem?.title}`



    useEffect(() => {
        if (localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if (localStorage?.expire > nowDate) {
                updateListOrganizationType();
            } else {
                navigate(`${path}/login`, { replace: true });
            }
        } else {
            navigate(`${path}/login`, { replace: true });
        }
    }, []);

    const indexOfLastOrg = currentPage * itemsPerPage;
    const indexOfFirstOrg = indexOfLastOrg - itemsPerPage;
    const currentOrgs = OrganizationList.slice(indexOfFirstOrg, indexOfLastOrg);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const deleteOrganization = (organization) => {
        setDeleteItem(null);
        axios.delete(`${url}/organization/${organization.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 201) {
                setColor('green')
                setMessage(response.data.message)
                updateListOrganization()
                setTimeout(()=> {
                    setMessage('')
                }, time)
            } else if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                setColor('orange')
                setMessage(response.data.message)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(error.response.data.err)
            setTimeout(()=> {
                setMessage('')
            }, time)
        });
    }

    const updateListOrganizationType = () => {
        axios.get(`${url}/organization/type`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setTypeOptions(response.data);
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setMessage('Произошла ошибка при выгрузке типов организаций, попробуйте позже');
                    setTimeout(() => {
                        setMessage('');
                    }, time);
                }
            })
            .catch(() => {
                setMessage('Произошла ошибка при выгрузке типов организаций, попробуйте позже');
                setTimeout(() => {
                    setMessage('');
                }, time);
            });
    };

    const saveEdit = () => {
        console.log('editedData', editedData)
        axios.patch(`${url}/organization/edit`, {...editedData, organizationId: editItem.id, Clarifications}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 201) {
                setColor('green')
                setMessage(response.data.message)
                updateListOrganization()
                updateListOrganizationType()
                setTimeout(()=> {
                    setMessage('')
                }, time)
            } else if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                setColor('orange')
                setMessage(response.data.message)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(error.response.data.err)
            setTimeout(()=> {
                setMessage('')
            }, time)
        })

        setEditItem(null);
    };

    const handleEdit = (item) => {
        axios.get(`${url}/organization/${item.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                setCauses(response.data.cause)
                setClarifications(response.data.clarifications)
            })
            .catch(error => {
                console.log(error)
            })
        setEditItem(item);
        setEditedData({ title: item.title, type: item.type, customType: '' });
    };

    const handleCheckboxClarificationsChange = (id) => {
        setClarifications(prevClars =>
            prevClars.map(clar=> {
                if (clar.causeId === SelectedCause) {
                    clar.clarification.map(el => {
                        if(el.id === id) {
                            el.selected = !el.selected
                        }
                    })
                }
                return clar;
            })
        )
    };


    return (
        <div style={{ margin: '1vw', marginTop: "20px" }}>
            {OrganizationList?.length ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Название</TableCell>
                                    <TableCell>Тип</TableCell>
                                    <TableCell>Редактировать</TableCell>
                                    <TableCell>Удалить</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentOrgs.map((item) => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.title}</TableCell>
                                            <TableCell>{item.type}</TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="primary" onClick={() => handleEdit(item)}>Изменить</Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="error" onClick={() => setDeleteItem(item)}>Удалить</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={Boolean(DeleteItem)} onClose={() => setDeleteItem(null)}>
                        <DialogTitle>Вы точно хотите удалить {DeleteItem?.name}?</DialogTitle>
                        <DialogContent>
                            <DialogActions>
                                <Button onClick={() => setDeleteItem(null)}>Нет</Button>
                                <Button onClick={() => deleteOrganization(DeleteItem)} color="error">
                                    Да
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <OrganizationClarificationModal editItem={editItem} setEditItem={setEditItem} editedData={editedData} setEditedData={setEditedData} TypeOptions={TypeOptions}
                                                    Causes={Causes} SelectedCause={SelectedCause} setSelectedCause={setSelectedCause} Clarifications={Clarifications}
                                                    handleCheckboxClarificationsChange={handleCheckboxClarificationsChange} saveEdit={saveEdit} headerTitle={headerTitle}/>
                    <ModalMessage message={Message} color={Color}/>
                    <Pagination
                        count={Math.ceil(OrganizationList.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                    />
                </>
            ) : (
                <h3>Список организаций пуст, создайте их в форме выше</h3>
            )}
        </div>
    );
};

export default memo(ListOrganization);