import React, {useEffect} from 'react';
import {Button, Typography} from '@mui/material';
import Box from "@mui/material/Box";
import axios from "axios";

const OldUrlNotification = () => {
    const newUrl = process.env.REACT_APP_URL_NEW;
    const url = process.env.REACT_APP_SERVER_URL;

    useEffect (() => {
        if (localStorage?.access) {
            axios.get(`${url}/accounts/code`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then(response => {
                    localStorage.code = response.data.url
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },[])

        return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }}>
            <Typography variant="h4" color="error" gutterBottom>
                ВНИМАНИЕ!
            </Typography>
            <Typography variant="h5" gutterBottom>
                Вход по текущей ссылке не будет работать с 01.08.2024!
            </Typography>
            <Typography variant="h5" gutterBottom>
                Вам необходимо изменить ссылку для входа в АС_МВК
            </Typography>
            <Typography variant="h5" gutterBottom style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'baseline',
            }}>
                Для входа с логином/паролем перейдите по ссылке: {' '}
                <Button variant="contained" href={newUrl} sx={{ marginTop: 2 }}>
                    новая ссылка
                </Button>
            </Typography>
            {localStorage.code && (
                <Typography variant="h5" gutterBottom style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'baseline',
                    }}>
                    Для входа без логина/пароля перейдите по ссылке: {' '}
                    <Button variant="contained" href={newUrl + localStorage.code} sx={{ marginTop: 2 }}>
                        новая ссылка
                    </Button>
                </Typography>
            )}
        </Box>
    );
};

export default OldUrlNotification;