export const checkPassword = (str) => {
    const minLength = 6;

    // Проверка на наличие как минимум 1 цифры от 0 до 9 и английской буквы
    const hasDigitAndLetter = /[0-9]/.test(str) && /[a-zA-Z]/.test(str);

    // Проверка на отсутствие русских или символов других языков
    const hasNoNonEnglishChars = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str);

    // Проверка длины пароля
    const isLengthValid = str.length >= minLength;

    // Возвращаем true, если все условия выполнены, иначе false
    return hasDigitAndLetter && hasNoNonEnglishChars && isLengthValid;
}