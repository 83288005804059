import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Checkbox, ListItemText, Chip } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import axios from 'axios';
import './SeachApplication.css';
import { useNavigate } from 'react-router-dom';

const path = process.env.REACT_APP_PATH;
const url = process.env.REACT_APP_SERVER_URL;

const SeachApplication = ({
                              organization, setOrganization, setStartDate, startDate, setEndDate, endDate, fetchApplications, setSearchFilter,
                              Reason, setReason, setStatus, Status, setDispetcher, dispetcher, setCurrentPage, setAllApplication, exportToXLSX
                          }) => {

    let navigate = useNavigate();

    const [listOgranization, setListOgranization] = useState([]);
    const [message, setMessage] = useState('');
    const [ReasonOptions, setReasonOptions] = useState([]);
    const [listUser, setListUser] = useState([]);

    const StatusArr = [
        { 'value': 'В обработке', 'status': 'create' },
        { 'value': 'В работе', 'status': 'inwork' },
        { 'value': 'Работы завершены', 'status': 'closed' },
        { 'value': 'Авария не в сфере деятельности АО "Мосводоканал"', 'status': 'canceled' },
        { 'value': 'Нарушений на сетях водоснабжения и водоотведения не обнаружено', 'status': 'canceledOk' }
    ];

    useEffect(() => {
        if (Number(localStorage.status) === 0) {
            setDispetcher([Number(localStorage?.id)])
        }

        if (Number(localStorage?.status) >= 2) {
            axios.get(`${url}/organization`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setListOgranization([{ id: -1, title: "Все организации" }, ...response.data.organizations])
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, { replace: true });
                    } else {
                        setMessage('Произошла ошибка')
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setMessage(error.err)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })

            axios.get(`${url}/cause/all`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setReasonOptions(response.data.uniqueObjects)
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, { replace: true });
                    } else {
                        setMessage('Произошла ошибка')
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setMessage(error.err)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        } else {
            axios.get(`${url}/cause/${localStorage?.type}`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setReasonOptions(response.data.uniqueObjects)
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, { replace: true });
                    } else {
                        setMessage('Произошла ошибка')
                    }
                })
                .catch((error) => {
                    setMessage(error.err)
                })

            if (organization && Number(localStorage?.status) !== 0)
                axios.get(`${url}/accounts/listUserAdmin/${organization}`, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if (response.status === 201) {
                            setListUser(response.data)
                        } else if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else {
                            setMessage(response.data?.message)
                            setTimeout(() => {
                                setMessage('')
                            }, 5000)
                        }
                    })
                    .catch((error) => {
                        setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    })

        }

    }, [])

    useEffect(() => {
        if (organization && Number(localStorage?.status) !== 0) {
            axios.get(`${url}/accounts/listUserAdmin/${organization}`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setDispetcher([])
                        setReason([])
                        setListUser(response.data)
                    } else if (response.status === 210) {
                        navigate(`${path}/changePassword`, { replace: true });
                    } else {
                        setMessage(response.data?.message)
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        }
    }, [organization])

    const handleSearch = async (event) => {
        event.preventDefault();
        setCurrentPage(1) // ставим 1 страницу
        setAllApplication([0]) // ставим в массив заявок 0: чтобы отобразить внизу страницу идкт загрузка
        fetchApplications(true)
    }

    const handleStartDateChange = (date) => {
        const selectedStartDate = date ? format(date, 'yyyy-MM-dd') : '';
        setStartDate(selectedStartDate);

        if (endDate && selectedStartDate > endDate) {
            setEndDate(selectedStartDate);
        }
    };

    const handleEndDateChange = (date) => {
        const selectedEndDate = date ? format(date, 'yyyy-MM-dd') : '';

        if (selectedEndDate >= startDate) {
            setEndDate(selectedEndDate);
        }
    };

    const cleanFilter = () => {
        setAllApplication([0])
        fetchApplications()
        setOrganization(localStorage?.organizationId)
        const currentDate = new Date();
        setStartDate(currentDate.toISOString().split('T')[0])
        setEndDate(currentDate.toISOString().split('T')[0])
        setDispetcher([])
        setStatus([])
        setReason([])
        setSearchFilter(false)
    }

    return (
        <div style={{ marginTop: '10px' }}>
            {message ? <p>{message}</p> : <></>}
            <form id='formSearch'>
                <div>
                    <div className='formBlock'>
                        {Number(localStorage?.status) >= 2 ? (
                            <FormControl variant="outlined" style={{ margin: '10px', width: '100%' }}>
                                <InputLabel>Выберите организацию</InputLabel>
                                <Select
                                    label="Выберите организацию"
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                                >
                                    {listOgranization.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <></>
                        )}

                        <FormControl variant="outlined" style={{ margin: '10px', width: '100%' }}>
                            <InputLabel>Выберите причину(ы)</InputLabel>
                            <Select
                                label="Выберите причину(ы)"
                                value={Reason}
                                onChange={(e) => setReason(e.target.value)}
                                multiple
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            <Chip key={value} label={ReasonOptions.find(el => el.causeCode === value)?.cause} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                            >
                                {ReasonOptions.map((option) => (
                                    <MenuItem key={option.causeCode} value={option.causeCode}>
                                        <ListItemText primary={option.cause} />
                                        <Checkbox checked={Reason.indexOf(option.causeCode) > -1} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className='formBlock'>
                        {Number(localStorage?.status) !== 0 ? (
                            <FormControl variant="outlined" style={{ margin: '10px', width: '100%' }}>
                                <InputLabel>Выберите диспетчера</InputLabel>
                                <Select
                                    label="Выберите диспетчера"
                                    value={dispetcher}
                                    onChange={(e) => setDispetcher(e.target.value)}
                                    multiple
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <Chip key={value} label={listUser.find(el => el.id === value)?.name} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                                >
                                    {listUser.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <ListItemText primary={option.name} />
                                            <Checkbox checked={dispetcher.indexOf(option.id) > -1} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <></>
                        )}

                        <FormControl variant="outlined" style={{ margin: '10px', width: '100%' }}>
                            <InputLabel>Выберите статус(ы)</InputLabel>
                            <Select
                                label="Выберите статус(ы)"
                                value={Status}
                                onChange={(e) => setStatus(e.target.value)}
                                multiple
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            <Chip key={value} label={StatusArr.find(el => el.status === value)?.value} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                            >
                                {StatusArr.map((option, index) => (
                                    <MenuItem key={index} value={option.status}>
                                        <ListItemText primary={option.value} />
                                        <Checkbox checked={Status.indexOf(option.status) > -1} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className='formBlockDate' style={{ display: "flex", justifyContent: "center"}}>
                        <div className='dateBlock'>
                            <label htmlFor="startDate">Выберите начальную дату:</label>
                            <DatePicker
                                selected={startDate ? new Date(startDate) : null}
                                onChange={handleStartDateChange}
                                dateFormat="dd.MM.yyyy"
                                placeholderText="Выберите дату"
                                locale={ru}
                            />
                        </div>

                        <div className='dateBlock'>
                            <label htmlFor="endDate">Выберите конечную дату:</label>
                            <DatePicker
                                selected={endDate ? new Date(endDate) : null}
                                onChange={handleEndDateChange}
                                dateFormat="dd.MM.yyyy"
                                placeholderText="Выберите дату"
                                locale={ru}
                            />
                        </div>
                    </div>

                    <div className='formBlockDate' style={{ display: "flex", justifyContent: "center"}}>
                        <Button onClick={handleSearch} variant="contained" component="span" style={{backgroundColor: '#1976d2', margin: '20px'}}>
                            Применить фильтр</Button>
                        <Button onClick={cleanFilter} variant="contained" component="span" style={{backgroundColor: '#d32f2f', margin: '20px'}}>
                            Сбросить фильтр</Button>
                        <Button onClick={exportToXLSX} variant="contained" component="span" style={{backgroundColor: '#00b285', margin: '20px'}}>
                            Выгрузить в XLSX
                        </Button>
                    </div>
                </div>

            </form>

        </div>
    );
};

export default SeachApplication;