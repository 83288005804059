import { Button, Modal, Box, TextField, InputLabel, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import './ButtonError.css';
import axios from 'axios';
import { formatter } from '../../utils/phoneNumber';
import ModalMessage from "../ModalMessage/ModalMessage";

const url = process.env.REACT_APP_SERVER_URL;

function ButtonError() {
    const time = 2000;
    const [isOpenApp, setIsOpenApp] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [textError, setTextError] = useState('');
    const [photo, setPhoto] = useState(null);
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [phone, setPhone] = useState(localStorage?.phone || '');
    const [name, setName] = useState(localStorage?.name || '');
    const [loading, setLoading] = useState(false);

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        setPhoto(file);
    };

    const handleOpen = () => {
        setStateError(true);
    };

    const handleClose = () => {
        setStateError(false);
    };

    const modalClosed = () => {
        setStateError(false);
        setIsOpenApp(false);
    };

    const downloadInstruction = () => {
        axios.get(`${url}/instruction/sendDoc`, { responseType: 'blob' })
            .then(response => {
                if (response.status === 500) {
                    console.log('err', response.data);
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'instruction.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error('Ошибка скачивания инструкции:', error.message);
            });
    };

    const sendError = () => {
        setLoading(true);
        if(!photo && !textError) {
            setColor('red')
            setMessage('Опишите проблему или приложите скриншот экрана');
            setTimeout(()=> {
                setMessage('')
            }, time)
            return
        }

        if(!phone) {
            setColor('red')
            setMessage('Укажите номер телефона для связи');
            setTimeout(()=> {
                setMessage('')
            }, time)
            return
        }
        const checkNumber = formatter(phone, 'ru')
        if (!checkNumber) {
            setColor('red')
            setMessage('Неверный формат или номер телефона')
            setTimeout(() => {
                setMessage('')
            }, time)
            return
        }

        if(!name) {
            setColor('red')
            setMessage('Укажите наименование')
            setTimeout(() => {
                setMessage('')
            }, time)
            return
        }

        const formData = new FormData();
        formData.append('textError', textError);
        formData.append('photo', photo);
        formData.append('phone', phone);
        formData.append('name', name);

        axios.post(`${url}/errorSend`, formData, {
            headers: {
                'jwt-token': localStorage?.access,
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setLoading(false);
                    handleClose();
                    setTextError('');
                    setPhoto(null);
                    setPhone(localStorage?.phone || '');
                    setName(localStorage?.name || '');
                    setColor('green')
                    setMessage(response.data.message);
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                } else if(response.status === 202) {
                    handleClose();
                    setLoading(false);
                    setTextError('');
                    setPhoto(null);
                    setPhone(localStorage?.phone || '');
                    setName(localStorage?.name || '');
                    setColor('orange')
                    setMessage(response.data.message);
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch(error => {
                setColor('red')
                setLoading(false);
                setMessage('Произошла ошибка, повторите позже');
                setTimeout(()=> {
                    setMessage('')
                }, time)
            });
    };

    return (
        <div className='buttonDiv'>
            <Button
                onClick={() => setIsOpenApp(true)}
                sx={{
                    top: '5px',
                    right: '20px',
                    color: 'red',
                    backgroundColor: '#add5f5',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    padding: '12px',
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                !
            </Button>

            <Modal
                open={isOpenApp}
                onClose={handleClose}
                size='medium'
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <div style={{display: "flex", alignItems: 'baseline'
                    }}>

                        <div style={{
                            marginTop: '30px',
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "flex-start",
                        }}>

                            {stateError ? (
                                <div style={{
                                    marginTop: '30px',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            <TextField
                                                type="text"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                label="Номер телефона для связи"
                                                variant="outlined"
                                                className='input'
                                                sx={{width: '100%', marginTop: '10px'}}
                                            />
                                            <TextField
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                label="Наименование"
                                                variant="outlined"
                                                className='input'
                                                sx={{width: '100%', marginTop: '10px'}}
                                            />
                                            <TextField
                                                type="text"
                                                value={textError}
                                                onChange={(e) => setTextError(e.target.value)}
                                                label="Опишите проблему"
                                                variant="outlined"
                                                className='input'
                                                multiline
                                                sx={{width: '100%', marginTop: '10px'}}
                                                maxRows={4}
                                            />
                                        </div>

                                    </div>

                                    <div>
                                        <InputLabel htmlFor="file">Прикрепите скриншот:</InputLabel>
                                        <input
                                            id="file"
                                            type="file"
                                            onChange={handlePhotoChange}
                                            style={{display: 'none'}}
                                        />
                                        <label htmlFor="file">
                                            <Button variant="contained" component="span">
                                                Выбрать файл
                                            </Button>
                                        </label>
                                    </div>
                                    <Button onClick={sendError} sx={{margin: '20px'}} disabled={loading}>Отправить
                                        информацию</Button>
                                    {loading && <CircularProgress/>}
                                </div>
                            ) : (
                                <>
                                    <Button onClick={handleOpen} sx={{margin: '20px'}} variant="contained" color="error">Сообщить
                                        об ошибке</Button>
                                    <Button onClick={downloadInstruction} sx={{margin: '20px'}} variant="contained"
                                            color="primary">Скачать инструкцию</Button>
                                </>
                            )}
                            <Button onClick={modalClosed} sx={{margin: '20px'}}>❌</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <ModalMessage message={Message} color={Color}/>

        </div>
    );
}

export default ButtonError;