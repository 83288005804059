import React, { useEffect, useState} from 'react';
import ApplicationForm from '../ApplicationForm/ApplicationForm';
import { useNavigate } from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import Header from '../Header/Header';
import axios from 'axios';
import ButtonError from '../ButtonError/ButtonError';
import InstructionUploadButton from "../InstructionUploadButton/InstructionUploadButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const hostOld = process.env.REACT_APP_HOST_OLD;
const hostNow = window.location.host;
const newUrl = process.env.REACT_APP_URL_NEW;

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const Application = () => {
    const [application, setApplication] = useState(true);
    const [alertText, setAlertText] = useState(false);
    let navigate = useNavigate();

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire > nowDate){
                axios.post(`${url}/token/token`, localStorage, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else if(response.status === 200) {
                            if(hostOld === hostNow) {
                                if(Number(localStorage?.status) === 0) {
                                    // запрос для получения строки
                                    if(!localStorage.code){
                                        axios.get(`${url}/accounts/code`, {
                                            headers: {
                                                'jwt-token': localStorage?.access,
                                            }
                                        })
                                            .then(response => {
                                                localStorage.code = response.data.url
                                                setAlertText(true)

                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    } else {
                                        setAlertText(true)
                                    }
                                } else {
                                    setAlertText(true)
                                }
                            }
                            console.log('успешная авторизация')
                        } else {
                            navigate(`${path}/login`, { replace: true });
                            // console.log('токена нет на сервере')
                        }
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            } else {
                navigate(`${path}/login`, { replace: true });
                // console.log('истекло время для токена')
            }
        } else {
            navigate(`${path}/login`, { replace: true });
            // console.log('нет токена на клиенте')
        }
    }, [])

    const handleClose = () => {
        setAlertText(false);
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                <Header setApplication={setApplication} application={application}/>
                <ButtonError/>
                {Number(localStorage?.status) === 3 ?
                    <InstructionUploadButton/>
                    :
                    <></>
                }
            </div>
            <div style={{margin: '1vw', marginTop: "20px"}}>

                {localStorage.userName && (
                    <Typography  variant={'h5'}>{localStorage.userName}</Typography>
                )}
            </div>
            <div>
                {/*{alertText ? (*/}
                {/*    <Modal*/}
                {/*        open={alertText}*/}
                {/*        aria-labelledby="modal-modal-title"*/}
                {/*        aria-describedby="modal-modal-description"*/}
                {/*    >*/}
                {/*        <Box sx={{*/}
                {/*            position: 'absolute',*/}
                {/*            top: '50%',*/}
                {/*            left: '50%',*/}
                {/*            transform: 'translate(-50%, -50%)',*/}
                {/*            width: 800,*/}
                {/*            bgcolor: 'background.paper',*/}
                {/*            border: '2px solid #000',*/}
                {/*            boxShadow: 24,*/}
                {/*            p: 4,*/}
                {/*        }}>*/}

                {/*            <Typography variant="h4" color="error" gutterBottom>*/}
                {/*                ВНИМАНИЕ!*/}
                {/*            </Typography>*/}
                {/*            <Typography variant="h5" gutterBottom>*/}
                {/*                Вход по текущей ссылке не будет работать с 01.08.2024!*/}
                {/*            </Typography>*/}
                {/*            <Typography variant="h5" gutterBottom>*/}
                {/*                Вам необходимо изменить ссылку для входа в АС_МВК*/}
                {/*            </Typography>*/}
                {/*            <Typography variant="h5" gutterBottom>*/}
                {/*                - для входа с логином/паролем перейдите по ссылке:{' '}*/}
                {/*                <a href={newUrl}>новая ссылка</a>*/}
                {/*            </Typography>*/}
                {/*            {localStorage.code &&*/}
                {/*                <Typography variant="h5" gutterBottom>*/}
                {/*                    - для входа без логина/пароля перейдите по ссылке:{' '}*/}
                {/*                    <a href={newUrl+localStorage.code}>ссылка</a>*/}
                {/*                </Typography>}*/}
                {/*            <Button variant="contained" onClick={handleClose}>Информация принята</Button>*/}
                {/*        </Box>*/}
                {/*    </Modal>*/}
                {/*):(<></>)}*/}
                {application ? (
                    <>
                        <ApplicationForm/>
                    </>
                ):(
                    <>
                        {/*<ListApplication/>*/}
                    </>
                )}
            </div>
        </>
    );
};

export default Application;