import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, AppBar, Toolbar, Typography } from '@mui/material';

const path = process.env.REACT_APP_PATH;

const Header = ({ application, setApplication, applicationNavigate }) => {
    let navigate = useNavigate();

  const handleLogout = () => {
      localStorage.clear();
      navigate(`${path}/login`, { replace: true });
  };

  const changeList = () => {
    // const nowDate = Math.floor(Date.now() / 1000);
    // if(localStorage?.expire > nowDate){
    //     setApplication((prev) => !prev)
    // } else {
    //     navigate(`${path}/login`, { replace: true });
    //     console.log('истекло время для токена')
    // }
  }

  const createUser = () => {
    navigate(`${path}/createUser`, { replace: true });
  }

  const createOrganization = () => {
        navigate(`${path}/organization`, { replace: true });
  }

    const formApplication = () => {
        // setApplication(true)
        navigate(`${path}/application`, { replace: true });
    }

    const listApplication = () => {
        // setApplication(false)
        navigate(`${path}/listApplication`, { replace: true });
    }
  return (
    <AppBar position="static" sx={{ backgroundColor: '#add5f5' }}>
      <Toolbar>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black'}}>
        Передача аварийных заявок Мосводоканал
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}>
        {localStorage?.organization}
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}>
          {localStorage?.name}
        </Typography>
          <Button color="inherit" onClick={formApplication}  sx={{ color: 'black' }}>
              Оформление заявки
          </Button>

          <Button color="inherit" onClick={listApplication}  sx={{ color: 'black' }}>
              Список заявок
          </Button>

        {/*{applicationNavigate ? (*/}
        {/*    <Button color="inherit" onClick={applicationNavigate}  sx={{ color: 'black' }}>*/}
        {/*        Перейти к оформлению заявки*/}
        {/*    </Button>*/}
        {/*):(*/}
        {/*    application ? (*/}
        {/*        <Button color="inherit" onClick={changeList}  sx={{ color: 'black' }}>*/}
        {/*            Показать список заявок*/}
        {/*        </Button>*/}
        {/*    ) : (*/}
        {/*        <Button color="inherit" onClick={changeList}  sx={{ color: 'black' }}>*/}
        {/*            Перейти к оформлению заявки*/}
        {/*        </Button>*/}
        {/*    )*/}
        {/*)}*/}
        {Number(localStorage?.status) ? (
            <Button size="small" color="inherit" style={{margin: "10px"}} onClick={createUser}  sx={{ color: 'black' }}> 
            Управление аккаунтами</Button>           
        ):(
            <></>
        )}
        {Number(localStorage?.status) === 3 ? (
            <Button size="small" color="inherit" style={{margin: "10px"}} onClick={createOrganization}  sx={{ color: 'black' }}>
            Управление организациями</Button>
        ):(
            <></>
        )}
        <Button onClick={handleLogout} sx={{ color: 'black' }}>
          Выйти
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
