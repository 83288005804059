function getDescription(str) {
  switch (str) {
    case 'water_flow':
      return 'УТЕЧКА ВОДЫ';
    case 'flooding':
      return 'ПОДТОПЛЕНИЕ';
    case 'water_quality':
      return 'КАЧЕСТВО ВОДЫ';
    case 'water_supply_disruption':
      return 'НАРУШЕНИЕ ПОДАЧИ ВОДЫ';
    case 'luke':
      return 'КОЛОДЦЫ';
    case 'blockage':
      return 'ЗАСОРЫ';
    case 'dips':
      return 'ПРОВАЛЫ'; 
    case 'smell':
      return 'ЗАПАХ';
    default:
      return 'не указана причина';
  }
}

module.exports = { getDescription }