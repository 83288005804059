import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField} from '@mui/material';
import axios from 'axios';
import { checkPassword } from '../../utils/checkPassword';
import './ChangePassword.css'

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const ChangePassword = () => {
    let navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const changePassword = (event) => {
        event.preventDefault();
        const checkPass = checkPassword(newPassword)
        if (!checkPass ) {
            setMessage('Пароль не подходит под требования');
            setTimeout(() => {
                setMessage('')
            }, 6000);
        } else {
            setMessage('');
            axios.post(`${url}/password/changePassword`, { newPassword }, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
            .then((response) => {
                if(response.status === 201) {
                    navigate(`${path}/application`, { replace: true });
                } else {
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 7000)
                }
            })
            .catch((err) => {
                setMessage('Произошла ошибка, попробуйте позже')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
        }
    }

    return (
        <div className='containerdiv'>
            <h3>
                В целях соблюдения политики безопасности Вам необходимо изменить пароль.
                Придумайте новый пароль, состоящий из 6 символов с использованием английских букв и цифр
            </h3>
            <form onSubmit={changePassword} id='changePassword' autoComplete="off"> 
                <TextField
                    type="text"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    label="Новый пароль"
                    variant="outlined"
                    className='input'
                />
                <Button type="submit" sx={{margin: '20px'}} variant="contained" color="primary">
                        Изменить пароль
                </Button>
            </form>
            {message? (<h3 id='messageContact'>{message}</h3>):(<></>)}
        </div>
    );
};

export default ChangePassword;
