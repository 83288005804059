import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    InputLabel, List, ListItem,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, {memo} from 'react';


const OrganizationClarificationModal = ({ editItem, setEditItem, editedData, setEditedData, TypeOptions, Causes, SelectedCause, setSelectedCause, Clarifications, handleCheckboxClarificationsChange, saveEdit, headerTitle }) => {

    return (
        <>
            <Dialog
                open={Boolean(editItem)}
                onClose={() => setEditItem(null)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>{headerTitle}</DialogTitle>
                <DialogContent style={{ height: '700px', overflowY: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Название"
                            type="text"
                            value={editedData.title}
                            onChange={(e) => setEditedData({ ...editedData, title: e.target.value })}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Тип</InputLabel>
                            <Select
                                value={editedData.type}
                                onChange={(e) => setEditedData({ ...editedData, type: e.target.value })}
                                label="Тип"
                            >
                                <MenuItem value="Другой">
                                    <span style={{ color: 'blue' }}>+ Добавить новый тип</span>
                                </MenuItem>
                                {TypeOptions.map((option) => (
                                    <MenuItem key={option.type} value={option.type}>
                                        {option.type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {editedData.type === 'Другой' && (
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Новый тип"
                                type="text"
                                value={editedData.customType}
                                onChange={(e) => setEditedData({ ...editedData, customType: e.target.value })}
                            />
                        )}

                        <div style={{ display: 'flex', flex: 1, marginTop: '20px', overflowY: 'auto' }}>
                            <div style={{ flex: 1, marginRight: '20px', overflowY: 'auto' }}>
                                <h3>Фильтр по причинам</h3>
                                {Causes.sort().map(el => (
                                    <div key={el.id}>
                                        <Button
                                            variant={SelectedCause === el.id ? 'contained' : 'outlined'}
                                            onClick={() => setSelectedCause(el.id)}
                                        >
                                            {el.cause}
                                        </Button>
                                    </div>
                                ))}
                            </div>

                            <div style={{ flex: 1, overflowY: 'auto' }}>
                                <h3>Уточнения</h3>
                                <List style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                                    {Clarifications.filter(el => el.causeId === SelectedCause)[0]?.clarification.sort().map(clar => (
                                        <React.Fragment key={clar.id}>
                                            <ListItem style={{ padding: 0 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={clar.selected}
                                                            onChange={() => handleCheckboxClarificationsChange(clar.id)}
                                                        />
                                                    }
                                                    label={clar.clarification}
                                                />
                                            </ListItem>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditItem(null)}>Отмена</Button>
                    <Button onClick={saveEdit} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default memo(OrganizationClarificationModal);
