import React from 'react';
import { memo } from 'react';
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    List,
    ListItem
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import './UserOrganizationModal.css'

const UserOrganizationModal = ({ editOrg, setEditOrg, arrayOrg, selectedTypes, handleFilterChange, handleCheckboxChange, handleSaveOrg, filteredOrgs, setArrOrg, setSelectedTypes }) => {

    const handleClose = () => {
        setEditOrg(null)
        setArrOrg([])
        setSelectedTypes([])
    }

    return (
        <>
            <Dialog open={Boolean(editOrg)} onClose={() => handleClose()} id='DialogOpen'>
                <DialogTitle>Редактировать организации {editOrg?.name}</DialogTitle>
                <DialogContent id='DialogContentFixed'>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '20px' }}>
                            <h3>Фильтр по типу</h3>
                            {Array.from(new Set(arrayOrg.map(org => org.type)))
                                .sort()
                                .map(type => (
                                    <div key={type}>
                                        <Button
                                            variant={selectedTypes.includes(type) ? 'contained' : 'outlined'}
                                            onClick={() => handleFilterChange(type)}
                                        >
                                            {type}
                                        </Button>
                                    </div>
                                ))}
                        </div>
                        <div style={{ flex: 1 }}>
                            <h3>Организации</h3>
                            <List style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                                {filteredOrgs.map(org => (
                                    <React.Fragment key={org.id}>
                                        <ListItem style={{ padding: 0 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={org.available}
                                                        onChange={() => handleCheckboxChange(org.id)}
                                                    />
                                                }
                                                label={org.title}
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                            <Divider /> {/* Общий разделитель после всего списка */}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditOrg(null)}>Отмена</Button>
                    <Button onClick={handleSaveOrg} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default memo(UserOrganizationModal);