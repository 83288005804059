import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const InfoModal = ({ isOpenInfo, handleCloseInfo, handleSaveInfo, item }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSaveClick = () => {
    handleSaveInfo(inputValue, item);
    setInputValue('');
    handleCloseInfo();
  };

  return (
    <Modal
      open={isOpenInfo}
      onClose={handleCloseInfo}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <TextField
          label="Направить дополнительную информацию"
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button variant="contained" onClick={handleSaveClick}>Сохранить</Button>
        <Button variant="contained" onClick={handleCloseInfo}>Отменить</Button>
      </Box>
    </Modal>
  );
};

export default InfoModal;
